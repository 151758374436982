import { useOidc, useOidcUser } from '@axa-fr/react-oidc';
import { BaseLayout, OidcProvider } from '@uag/react-core';

import { ProfileShell } from 'profile/shell/ProfileShell';
import { getAuthorityUrl } from './urlHelpers';
export const Home = () => {
    const { logout, login } = useOidc();
    const { oidcUser, oidcUserLoadingState } = useOidcUser();

    return (
        <OidcProvider
            authority={getAuthorityUrl()}
            login={login}
            logout={logout}
            user={oidcUser}
            userLoadingState={oidcUserLoadingState}
        >
            <BaseLayout
                innerClassName="flex-grow overflow-auto text-base pb-8 pt-8 flex flex-col gap-8 text-text-default xs:pt-12 sm:pt-16"
                versionNumber={APP_VERSION}
            >
                <ProfileShell className="px-2 sm:px-6" />
            </BaseLayout>
        </OidcProvider>
    );
};
