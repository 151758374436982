import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
    AuthProvider,
    DefaultErrorBoundary,
    enableUserTracking,
    MantineOverrideProvider,
    Theme,
    ThemeProvider
} from '@uag/react-core';
import { BrowserRouter } from 'react-router-dom';

import { configurationIdentityServer } from 'app/configurationIdentityServer';
import { applicationInsightsReactPlugin } from 'utils/applicationInsights';
import { AppInsightsContextProvider } from 'utils/ApplicationInsightsContextProvider';
import { AppRoutes } from './AppRoutes';
import './MantineStyles.css';
import './App.css';
import '../i18n';
import { getCustomThemeOverrides } from './getCustomThemeOverrides';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: { retry: false, refetchOnWindowFocus: false, refetchOnReconnect: false, refetchOnMount: false }
    }
});

enableUserTracking();

export const App = () => {
    const theme = window.location.href.includes('doka') ? Theme.Doka : Theme.Umdasch;
    const customThemeOverrides = getCustomThemeOverrides();

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <MantineOverrideProvider customThemeOverrides={customThemeOverrides}>
                    <AuthProvider
                        authority={import.meta.env.VITE_APP_OIDC_AUTHORITY}
                        autoLogin={true}
                        configuration={configurationIdentityServer}
                    >
                        <AppInsightsContextProvider>
                            <QueryClientProvider client={queryClient}>
                                <DefaultErrorBoundary reactPlugin={applicationInsightsReactPlugin}>
                                    <Notifications />
                                    <AppRoutes />
                                </DefaultErrorBoundary>
                            </QueryClientProvider>
                        </AppInsightsContextProvider>
                    </AuthProvider>
                </MantineOverrideProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
};
